<template>
  <aCollapse
    class="travel-filters expandable mt-0 mb-10"
    activeKey="1"
    expandIconPosition="right"
  >
    <a-collapse-panel key="0">
      <template slot="header">
        <a-icon type="filter" class="mr-5" /> FILTRAR
      </template>

      <a-row class="fiter-collumns" :gutter="20">
        <a-col :span="6">
          <div class="travel-input">
            <label class="filled">Fornecedores</label>
            <a-select
              class="travel-input"
              placeholder="Selecione o fornecedor"
              v-model="filters.productSupplier"
              optionFilterProp="txt"
              show-search
              allow-clear
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of productSupplier.list"
                :key="index"
                :value="item.trading_name"
                :txt="item.trading_name"
              >
                {{ item.id }} -
                {{ item.trading_name.toUpperCase() }}
              </a-select-option>
            </a-select>
          </div>
        </a-col>

        <a-col :span="3">
          <div class="travel-input">
            <label class="filled">ID do contrato</label>
            <a-input
              placeholder="ID + Enter"
              v-model="filters.id"
              @pressEnter="onClickFilterContracts"
            />
          </div>
        </a-col>

        <a-col :span="3">
          <div class="travel-input">
            <label class="filled">Empresas</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              v-model="filters.companies.selected"
              show-search
              allow-clear
              style="width: 100%"
              optionFilterProp="txt"
              @change="onSelectCompany"
            >
              <a-select-option
                v-for="(item, index) of companies.list"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="travel-input">
            <label class="filled">Filiais</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              mode="multiple"
              v-model="filters.companyBranches.selected"
              show-search
              style="width: 100%"
              optionFilterProp="txt"
            >
              <a-select-option
                v-for="(item, index) of companyBranches.list"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </a-col>

        <a-col :span="6">
          <div class="travel-input">
            <label class="filled">Data da venda</label>
            <a-range-picker
              v-model="filters.period.selected"
              :format="['DD/MM/YYYY']"
              :value-format="'YYYY-MM-DD'"
              @change="getContracts()"
            />
          </div>
        </a-col>

        <a-col :span="6">
          <div class="travel-input">
            <label class="filled">Check-in</label>
            <a-range-picker
              v-model="filters.checkin.selected"
              :format="['DD/MM/YYYY']"
              :value-format="'YYYY-MM-DD'"
            />
          </div>
        </a-col>

        <a-col :span="4">
          <div class="field payments">
            <font class="label">Status</font>
            <a-checkbox-group
              style="width: 100%"
              v-model="filters.auditStatus.selected"
            >
              <a-checkbox
                v-for="(item, index) of [
                  { label: 'Pago', value: 'Realizado' },
                  { label: 'Em aberto', value: 'Em aberto' },
                ]"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </a-col>

        <a-col :span="7">
          <div class="field payments">
            <font class="label">Tipo de Produto</font>
            <a-checkbox-group
              style="width: 100%"
              v-model="filters.productsCategory.selected"
            >
              <a-checkbox
                v-for="(item, index) of filters.productsCategory.list"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </a-col>

        <a-col :span="8">
          <div class="field payments">
            <font class="label">Tipo de venda</font>

            <a-checkbox-group
              style="width: 100%"
              v-model="filters.saleType.selected"
            >
              <a-checkbox
                v-for="(item, index) of filters.saleType.list"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </a-col>

        <a-col :span="3">
          <div class="field payments">
            <font class="label">Marketing</font>
            <a-checkbox-group
              style="width: 100%"
              v-model="filters.marketing.selected"
            >
              <a-checkbox
                v-for="(item, index) of filters.marketing.list"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </div></a-col
        >
      </a-row>
      <a-divider class="mt-0 mb-15" />
      <a-button
        class="mb-0"
        type="primary"
        :loading="loading"
        @click="onClickFilterContracts()"
      >
        <a-icon type="search" /> Filtrar
      </a-button>
    </a-collapse-panel>
  </aCollapse>
</template>

<script>
import companyMixins from "@/mixins/companies/companyMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "BillsToPayFiltersCollapse",
  props: {
    filters: Object,
    loading: Boolean,
  },
  mixins: [companyMixins, companyBranchMixins, productSupplierMixins],
  data() {
    return {};
  },
  mounted() {
    this.companyBranches.pagination.perPage = 300;
    this.getCompanyBranches();
    this.getCompanies();
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();
  },
  methods: {
    onClickFilterContracts() {
      this.$emit("onClickFilterContracts", this.filters);
    },
    onSelectCompany(val) {
      this.companyBranches.filters.companyId = val;
      this.companyBranches.list = [];
      this.getCompanyBranches();
    },
  },
};
</script>

<style lang="sass" scoped>
.travel-filters
  .field.payments
    .ant-checkbox-wrapper
      width: auto
      margin: 0 !important
      padding: 4px 11px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  .field
    position: relative
    padding-top: 10px
    label
      font-size: 11px
      color: #999
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #434a53 !important
      top: -10px
      z-index: 1
</style>
